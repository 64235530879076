<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      Conferences
    </template>
  
    <template #cardBody>
      <!-- START Conferences Table -->
      <v-data-table
        :headers="headers"
        :items="filteredConferences"
        :items-per-page="-1"
        :expanded.sync="expanded"
        class="expanding-v-data-table"
        :sortBy.sync="sortBy"
        dense
      >
        <template #top>
          <v-row class="pa-2 flex-grow-0">
            <v-col cols="9">
              <v-text-field
                v-model="conferenceSearch"
                class="ml-2 mt-0"
                label="Filter by Conference Name"
                hide-details
              />
            </v-col>
            <v-col cols="3">
              <div class="pt-2">
                <v-btn
                  @click="openConferenceModal(null)"
                  color="primary"
                  block
                >
                  Add Conference
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>

        <template #item="{ item, expand, isExpanded }">
          <tr>
            <td class="font-weight-bold">{{ item.name }}</td>
            <td>{{ item.serviceUrl }}</td>
            <td>
              <div 
                class="w-100 d-flex align-center justify-center"
                style="height: 100%;"
              >
                <!-- Edit Conference -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="openConferenceModal(item)"
                      icon
                    >
                      <v-icon   
                        color="blue darken-2"
                        small
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  Edit Conference
                </v-tooltip>
                <!-- Add Conference Admin -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="openAdminModal(null, item)"
                      icon
                    >
                      <v-icon   
                        color="green darken-2"
                        small
                      >
                        mdi-account-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  Add Conference Admin
                </v-tooltip>
                <!-- Show Conference Admins -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.conferenceAdmins.length"
                      v-bind="attrs"
                      v-on="on"
                      @click="expand(!isExpanded)"
                      icon
                    >
                      <v-icon
                        :style="{'transform': isExpanded ? 'rotate(-180deg)' : ''}"
                        style="transition: transform .3s;"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ isExpanded ? "Hide Admins" : "Show Admins" }}
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ item, headers }">
          <td
            :colspan="headers.length"
            class="pa-0"
            style="background-color: #f8f8f8;"
          >
            <div
              v-if="item.conferenceAdmins.length == 0"
              class="px-4 py-1"
            >
              No admins found for this conference.
            </div>

            <!-- START Admin Rows -->
            <table style="width: 100%;">
              <tr
                v-for="(admin, index) in item.conferenceAdmins"
                :key="index"
              >
                <td
                  class="px-4 table-font"
                  style="width: 40%"
                >
                  {{ `${admin.firstName} ${admin.lastName}` }}
                </td>
                <td
                  class="px-4 table-font"
                  style="width: 40%"
                >
                  {{ admin.email }}
                </td>
                <td
                  class="px-4"
                  style="width: 20%"
                  align="center"
                >
                  <!-- Edit Admin -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="openAdminModal(admin, item)"
                        icon
                      >
                        <v-icon   
                          color="blue darken-2"
                          small
                        >
                          mdi-account-cog
                        </v-icon>
                      </v-btn>
                    </template>
                    Edit Admin
                  </v-tooltip>
                  <!-- Enable Admin -->
                  <v-tooltip v-if="admin.disabled" top>
                    <template v-slot:activator="{on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="enableAdmin(admin)"
                        icon
                      >
                        <v-icon   
                          color="green darken-2"
                          small
                        >
                          mdi-account-lock-open
                        </v-icon>
                      </v-btn>
                    </template>
                    Enable Admin
                  </v-tooltip>
                  <!-- Disable Admin -->
                  <v-tooltip v-if="!admin.disabled" top>
                    <template v-slot:activator="{on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="disableAdmin(admin)"
                        icon
                      >
                        <v-icon   
                          color="red accent-2"
                          small
                        >
                          mdi-account-lock
                        </v-icon>
                      </v-btn>
                    </template>
                    Disable Admin
                  </v-tooltip>
                  <!-- Impersonate Conference Admin -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="impersonateConferenceAdmin(item, admin)"
                        icon
                      >
                        <v-icon
                          color="blue-grey"
                          small
                        >
                          mdi-login
                        </v-icon>
                      </v-btn>
                    </template>
                    Login As Admin
                  </v-tooltip>
                  <v-tooltip top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="openSetTemporaryPasswordModal(admin)"
                          icon
                        >
                          <v-icon   
                            color="teal"
                            small
                          >
                            mdi-lock-reset
                          </v-icon>
                        </v-btn>
                      </template>
                      Set Temporary Password
                    </v-tooltip>
                </td>
              </tr>
            </table>
            <!-- START Admin Rows -->

            <v-divider/>
          </td>
        </template>

        <template #footer="{ props }">
          <v-divider v-if="props.pagination.itemsLength > 0"/>
          <div class="expanding-v-data-table--footer"></div>
        </template>
      </v-data-table>
      <!-- END Conferences Table -->

      <!-- START Conference Modal -->
      <app-modal
        :value="conferenceModalOpen"
        :persistent="true"
        :header-text="conferenceModalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <div class="d-flex flex-column justify-center align-center my-2">
            <span class="black--text body-1">Important notes for Service URL</span>
            <span class="black--text body-2">1: The Service URL is the URL of the website for the conference. (Example: <i>https://theacc.com</i>)</span>
            <span class="black--text body-2">2: Make sure to include the "https://" and not include any trailing characters like "/" or "/sports"!</span>
          </div>

          <v-row>
            <v-col>
              <v-text-field
                v-model="conferenceName"
                label="Conference Name"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="serviceUrl"
                label="Service URL"
              />
            </v-col>
          </v-row>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelConferenceModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitConference()"
            :loading="submitConferenceLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Save
          </v-btn>
        </template>
      </app-modal>
      <!-- END Conference Modal -->

      <!-- START Admin Modal -->
      <app-modal
        :value="adminModalOpen"
        :persistent="true"
        :header-text="adminModalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <v-row class="mt-2">
            <v-col cols="3">
              <v-text-field
                v-model="firstName"
                label="First Name"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="lastName"
                label="Last Name"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="email"
                label="Email"
              />
            </v-col>
          </v-row>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelAdminModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitAdmin()"
            :loading="submitAdminLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Save
          </v-btn>
        </template>
      </app-modal>
      <app-modal
        v-if="setTemporaryPasswordModalOpen"
        :value="setTemporaryPasswordModalOpen"
        :persistent="true"
        :header-text="`Set Temporary Password for ${setTemporaryPasswordUser.firstName} ${setTemporaryPasswordUser.lastName}`"
        width="auto"
        min-width="800px"
      >
        <template #subheader>
          <v-alert
            type="warning"
            text
          >
            The user will be prompted to reset their password after logging in with the temporary password.
          </v-alert>
        </template>
        <template #content>
          <v-form 
            ref="form"
            @submit.prevent="setTemporaryPassword"
          >
            <v-row>
              <!-- Temporary Password -->
              <v-col>
                <div class="mx-2">
                  <v-text-field
                    v-model="temporaryPassword"
                    label="Temporary Password"
                    hide-details="auto"
                    item-disabled="enable"
                    :rules="[
                      (input) => { return (input != null) || 'Required'},
                      (input) => { return (input.length >= 8) || 'Min 8 characters'},
                      (input) => { return (input.length < 256) || 'Max 256 characters'},
                      (input) => { return (input.toLowerCase() !== input) || 'Must contain an uppercase letter'},
                      (input) => { return (input.toUpperCase() !== input) || 'Must contain a lowercase letter'},
                      (input) => { return (
                        input.match(validSymbolRegex) !== null || 
                        input.indexOf('^') !== -1 || 
                        input.indexOf('[') !== -1 ||
                        input.indexOf(']') !== -1 ||
                        input.indexOf('/') !== -1 ||
                        input.indexOf('\\') !== -1
                      ) ||
                      'Must contain a symbol'},
                    ]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeSetTemporaryPasswordModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="setTemporaryPassword()"
            :loading="setTemporaryPasswordLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Confirm
          </v-btn>
        </template>
      </app-modal>
      <!-- END Admin Modal -->

    </template>
  </view-card-template>
</template>

<script>
import AppModal from "@/components/app/AppModal"
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from '@/data/controllers'
import Identity from '@/app/Identity'
import { SetImpersonationAdminId, SetImpersonationConferenceId } from "@/data/Requests"

export default {
  name: "ConferenceManagement",

  components: {
    AppModal,
    ViewCardTemplate,
  },

  data: () => ({
    loaded: false,
    
    conferenceSearch: "",

    headers: [
      { text: 'Conference', value: "name", width: "40%", },
      { text: 'Service URL', value: "serviceUrl", width: "40%", },
      { text: "Actions", sortable: false, width: "20%", align: "center", },
    ],
    sortBy: "name",

    conferences: [],
    expanded: [],

    conferenceToEdit: null,
    conferenceModalOpen: false,
    conferenceModalHeaderText: "",
    conferenceName: null,
    serviceUrl: null,
    submitConferenceLoading: false,

    adminToEdit: null,
    adminModalOpen: false,
    adminModalHeaderText: "",
    conferenceOfAdminToEdit: null,
    firstName: null,
    lastName: null,
    email: null,
    submitAdminLoading: false,
    setTemporaryPasswordModalOpen: false,
    setTemporaryPasswordLoading: false,
    setTemporaryPasswordUser: null,
    validSymbolRegex: /[$*.{}()?!@#%&,"><':;|_~`=+-]+/g,
    temporaryPassword: "",

  }),

  computed: {
    filteredConferences() {
      return this.conferences
        .filter(c =>
          c.name
            .toLocaleLowerCase()
            .includes(this.conferenceSearch.toLocaleLowerCase())
        )
    }
  },

  methods: {
    openSetTemporaryPasswordModal(user) {
      this.setTemporaryPasswordUser = user
      this.setTemporaryPasswordModalOpen = true
    },
    closeSetTemporaryPasswordModal() {
      this.setTemporaryPasswordModalOpen = false
      this.setTemporaryPasswordUser = null
      this.temporaryPassword = ""
    },

    async setTemporaryPassword() {
      if (!this.$refs.form.validate()) return
      this.setTemporaryPasswordLoading = true
      
      const res = await Controllers.UserController.AdminSetConfUserPassword(this.setTemporaryPasswordUser.id, this.temporaryPassword);

      if(res && !res.hasError) {
        this.$root.showSnackbar('Temporary password set successfully!', "success", 5000)
      }
      else this.$root.showSnackbar('Failed to set temporary password for voter.', "error", 5000)

      this.setTemporaryPasswordLoading = false
      this.closeSetTemporaryPasswordModal();
    },
    openConferenceModal(conference) {
      this.conferenceToEdit = conference
      this.conferenceModalOpen = true

      if(conference != null) {
        this.conferenceName = conference.name
        this.serviceUrl = conference.serviceUrl
      }

      this.conferenceModalHeaderText = 
        conference == null
          ? "Add Conference"
          : "Edit Conference"
    },

    cancelConferenceModal() {
      this.conferenceModalOpen = false
      this.conferenceToEdit = null

      this.conferenceName = null
      this.serviceUrl = null
    },

    openAdminModal(admin, conference) {
      this.adminToEdit = admin
      this.conferenceOfAdminToEdit = conference
      this.adminModalOpen = true

      if(admin != null) {
        this.firstName = admin.firstName
        this.lastName = admin.lastName
        this.email = admin.email
      }

      this.adminModalHeaderText = 
        admin == null
          ? "Add Admin"
          : "Edit Admin"
    },

    cancelAdminModal() {
      this.adminModalOpen = false
      this.adminToEdit = null
      this.conferenceOfAdminToEdit = null

      this.firstName = null
      this.lastName = null
      this.email = null
    },

    async submitConference() {
      if(this.submitConferenceLoading) return
      this.submitConferenceLoading = true
      // TODO: Validate

      const creating  = this.conferenceToEdit == null

      const conferenceSubmission = {
        id: creating ? 0 : this.conferenceToEdit.id,
        name: this.conferenceName,
        serviceUrl: this.serviceUrl,
      }

      if(creating) {
        const res = await Controllers.ConferenceController.SupportCreateConference(conferenceSubmission)

        if(res && !res.hasError) {
          this.conferences.push(res.data)
          this.$root.showSnackbar('Conference created successfully!', "success", 5000)
          this.cancelConferenceModal()
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to create conference.', "error", 5000)
        }
      }
      else {
        // Updating
        const res = await Controllers.ConferenceController.SupportUpdateConference(conferenceSubmission)

        if(res && !res.hasError) {
          const conference = res.data
          this.conferenceToEdit.name = conference.name
          this.conferenceToEdit.serviceUrl = conference.serviceUrl
          this.$root.showSnackbar('Conference edited successfully!', "success", 5000)
          this.cancelConferenceModal()
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to update conference.', "error", 5000)
        }
      }

      this.submitConferenceLoading = false
    },

    async submitAdmin() {
      if(this.submitAdminLoading) return
      this.submitAdminLoading = true
      // TODO: Validation

      const creating = this.adminToEdit == null
      const conference = this.conferenceOfAdminToEdit

      const adminSubmission = {
        id: creating ? 0 : this.adminToEdit.id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        conferenceId: conference.id,
        disabled: false,
      }
      
      if(creating) {
        const res = await Controllers.ConferenceAdminController.SupportCreateConferenceAdmin(adminSubmission)

        if(res && !res.hasError) {
          conference.conferenceAdmins.push(res.data)
          this.$root.showSnackbar('Conference admin created successfully!', "success", 5000)
          this.cancelAdminModal()
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to create conference admin.', "error", 5000)
        }
      }
      else {
        // Updating
        const res = await Controllers.ConferenceAdminController.SupportUpdateConferenceAdmin(adminSubmission)

        if(res && !res.hasError) {
          const admin = res.data
          this.adminToEdit.firstName = admin.firstName
          this.adminToEdit.lastName = admin.lastName
          this.adminToEdit.email = admin.email
          this.adminToEdit.conferenceId = admin.conferenceId
          this.adminToEdit.disabled = admin.disabled
          this.$root.showSnackbar('Conference admin edited successfully!', "success", 5000)
          this.cancelAdminModal()
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to update conference admin.', "error", 5000)
        }
      }

      this.submitAdminLoading = false
    },

    async enableAdmin(admin) {
      const res = await Controllers.ConferenceAdminController.SupportEnableConferenceAdmin(admin.id)

      if(res && !res.hasError) {
        admin.disabled = false
        this.$root.showSnackbar('Conference admin enabled successfully!', "success", 5000)
      }
      else {
        // Error handling
        this.$root.showSnackbar('Failed to enable conference admin', "error", 5000)
      }
    },

    async disableAdmin(admin) {
      const res = await Controllers.ConferenceAdminController.SupportDisableConferenceAdmin(admin.id)

      if(res && !res.hasError) {
        admin.disabled = true
        this.$root.showSnackbar('Conference admin disabled successfully!', "success", 5000)
      }
      else {
        // Error handling
        this.$root.showSnackbar('Failed to disable conference admin', "error", 5000)
      }
    },

    impersonateConferenceAdmin(conference, admin) {
      Identity.SetAdmin(admin)
      SetImpersonationAdminId(admin.id)

      Identity.SetConference(conference)
      SetImpersonationConferenceId(conference.id)

      this.$router.push({ name: "AwardManagement" })
    },
  },

  async created() {
    this.$root.updateTitle("Conferences")

    const res = await Controllers.ConferenceController.SupportListConferences()

    if(res && !res.hasError) {
      const conferences = res.data

      this.conferences = conferences
    }
    else {
      // Error handling
    }

    this.$store.dispatch("clearConferenceSports")

    this.loaded = true
  },
  
}
</script>

<style lang="scss">
.table-font {
  font-size: 0.875rem;
}
</style>