<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      Account Management
    </template>

    <template #cardBody>
      <v-data-table 
        :headers="headers" 
        :items="filteredUsers" 
        :items-per-page="20" 
        class="expanding-v-data-table"
        dense
      >
        <template #top>
          <v-row class="pa-2 flex-grow-0">
            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-model="filterName"
                class="ml-2 mt-0"
                label="Filter by User Name"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-combobox
                v-model="filterStatus"
                :items="statusList"
                class="ml-2 mt-0"
                label="Filter by Status"
                multiple
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <div
                class="d-flex align-center"
                style="height: 100%;"
              >
                <v-btn
                  @click="openModal(null)"
                  color="primary"
                  hide-details
                  block
                >
                  New User
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>

        <template #item="{ item }">
          <tr>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.school }}</td>
            <td>{{ item.email }}</td>
            <td>
              <v-row no-gutters>
                <v-col rows="12">
                  <div
                    class="w-100 d-flex align-center justify-center"
                    style="height: 100%;"
                  >
                    <!-- Edit -->
                    <v-tooltip top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="openModal(item)"
                          icon
                        >
                          <v-icon   
                            color="blue darken-2"
                            small
                          >
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      Edit User
                    </v-tooltip>
                    <!-- Enable -->
                    <v-tooltip v-if="item.disabled" top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="enableUser(item)"
                          icon
                        >
                          <v-icon   
                            color="amber accent-2"
                            small
                          >
                            mdi-account-lock-open
                          </v-icon>
                        </v-btn>
                      </template>
                      Enable User
                    </v-tooltip>
                    <!-- Disable -->
                    <v-tooltip v-if="!item.disabled" top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="openDisableUserModal(item)"
                          icon
                        >
                          <v-icon   
                            color="red accent-2"
                            small
                          >
                            mdi-account-lock
                          </v-icon>
                        </v-btn>
                      </template>
                      Disable User
                    </v-tooltip>
                    <!-- Set Temporary Password -->
                    <v-tooltip top>
                      <template v-slot:activator="{on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="openSetTemporaryPasswordModal(item)"
                          icon
                        >
                          <v-icon   
                            color="teal"
                            small
                          >
                            mdi-lock-reset
                          </v-icon>
                        </v-btn>
                      </template>
                      Set Temporary Password
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>

        <!-- Expanding Footer -->
        <template #footer="{ props }">
          <v-divider v-if="props.pagination.itemsLength > 0"/>
          <div class="expanding-v-data-table--footer"></div>
        </template>
      </v-data-table>
       
      <app-modal
        v-if="setTemporaryPasswordModalOpen"
        :value="setTemporaryPasswordModalOpen"
        :persistent="true"
        :header-text="`Set Temporary Password for ${setTemporaryPasswordUser.firstName} ${setTemporaryPasswordUser.lastName}`"
        width="auto"
        min-width="800px"
      >
        <template #subheader>
          <v-alert
            type="warning"
            text
          >
            The user will be prompted to reset their password after logging in with the temporary password.
          </v-alert>
        </template>
        <template #content>
          <v-form 
            ref="form"
            @submit.prevent="setTemporaryPassword"
          >
            <v-row>
              <!-- Temporary Password -->
              <v-col>
                <div class="mx-2">
                  <v-text-field
                    v-model="temporaryPassword"
                    label="Temporary Password"
                    hide-details="auto"
                    item-disabled="enable"
                    :rules="[
                      (input) => { return (input != null) || 'Required'},
                      (input) => { return (input.length >= 8) || 'Min 8 characters'},
                      (input) => { return (input.length < 256) || 'Max 256 characters'},
                      (input) => { return (input.toLowerCase() !== input) || 'Must contain an uppercase letter'},
                      (input) => { return (input.toUpperCase() !== input) || 'Must contain a lowercase letter'},
                      (input) => { return (
                        input.match(validSymbolRegex) !== null || 
                        input.indexOf('^') !== -1 || 
                        input.indexOf('[') !== -1 ||
                        input.indexOf(']') !== -1 ||
                        input.indexOf('/') !== -1 ||
                        input.indexOf('\\') !== -1
                      ) ||
                      'Must contain a symbol'},
                    ]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeSetTemporaryPasswordModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="setTemporaryPassword()"
            :loading="setTemporaryPasswordLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Confirm
          </v-btn>
        </template>
      </app-modal>

      <app-modal
        v-if="disableUserModal"
        :value="disableUserModal"
        :persistent="true"
        :header-text="`Disable user ${setTemporaryDisableUser.firstName} ${setTemporaryDisableUser.lastName}`"
        width="auto"
        min-width="800px"
      >
        <template #subheader>
          <v-alert
            type="warning"
            text
          >
            Are you sure you want to disable the user?
          </v-alert>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDisableUserModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="disableUser(setTemporaryDisableUser)"
            :loading="setTemporaryPasswordLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Confirm
          </v-btn>
        </template>
      </app-modal>
      <app-modal
        v-if="modalOpen"
        :value="modalOpen"
        :persistent="true"
        :header-text="modalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <v-row
            class="my-3"
            no-gutters
          >
            <!-- First Name -->
            <v-col cols="3">
              <div class="mx-2">
                <v-text-field
                  v-model="modalUser.firstName"
                  label="First Name*"
                  hide-details="auto"
                  item-disabled="enable"
                  :rules="[value => !!value || 'Required.', value => (value && value.length >= 3) || 'Min 3 characters']"
                />
              </div>
            </v-col>
            <!-- Last Name -->
            <v-col cols="3">
              <div class="mx-2">
                <v-text-field
                  v-model="modalUser.lastName"
                  label="Last Name*"
                  hide-details="auto"
                  item-disabled="disable"
                  :rules="[value => !!value || 'Required.', value => (value && value.length >= 3) || 'Min 3 characters']"
                />
              </div>
            </v-col>
            <!-- Email -->
            <v-col cols="6">
              <div class="mx-2">
                <v-text-field
                  v-model="modalUser.email"
                  label="Email*"
                  hide-details="auto"
                  item-disabled="disable"
                  :rules="[value => !!value || 'Email is Required.', v => /.+@.+/.test(v) || 'E-mail must be valid']"
                />
              </div>
            </v-col>
          </v-row>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitUser()"
            :loading="submitUserLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Apply
          </v-btn>
        </template>
      </app-modal>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppModal from "@/components/app/AppModal"
import Controllers from "@/data/controllers"
import Cognito from '../../app/Cognito';

export default {
  name: "UserMaintenance",

  components: {
      AppModal,
      ViewCardTemplate,
  },

  data: () => ({
    loaded: false,
    
    filterName: "",
    filterSport: "",
    filterStatus: ['Enabled'],
    filterSchools: [],
    filterCategories: [],

    userList: [],
    sportList: [],
    schoolList: [],
    categoryList: [],
    statusList: ['Enabled', 'Disabled'],

    headers: [
      {
        text: 'First Name',
        align: 'start',
        sortable: true,
        value: 'firstName'
      },
      { text: 'Last Name', value: 'lastName' },
      { text: 'School', value: 'school' },
      { text: 'Email', value: 'email' },
      { text: "Actions", sortable: false, align: "center" },
    ],

    modalOpen: false,
    modalHeaderText: "Edit User",
    setTemporaryPasswordModalOpen: false,
    setTemporaryPasswordLoading: false,
    setTemporaryPasswordUser: null,
    setTemporaryDisableUser: null,
    disableUserModal: false,
    validSymbolRegex: /[$*.{}()?!@#%&,"><':;|_~`=+-]+/g,
    temporaryPassword: "",
    creatingUser: false,
    conferenceId: null,
    modalUser: {},
    submitUserLoading: false,
  }),

  computed: {
    filteredUsers() {

      return this.userList
        .filter(user => this.filterName == null || this.filterName.length == 0
          || (`${user.firstName} ${user.lastName}`).toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())
          || (`${user.lastName}, ${user.firstName} `).toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())
        )
        .filter(user => this.filterStatus == null || this.filterStatus.length == 0
          || !user.disabled && this.filterStatus.includes("Enabled")
          || user.disabled && this.filterStatus.includes("Disabled")
        )


    },
  },

  methods: {
    openModal(item) {
      if (item == null) {
        // Creating
        this.creatingUser = true
        this.modalHeaderText = "New User"
        this.modalUser = {
          id: 0,
          firstName: "",
          lastName: "",
          email: "",
          school: "",
          globalSportId: null,
          teamId: null,
          disabled: false,
          conferenceId: 0,
          categoryId: 0,
          newUser: true,
        }
      }
      else {
        // Editing
        this.creatingUser = false
        this.modalHeaderText = "Edit User"
        Object.assign(this.modalUser, item)
      }

      this.modalOpen = true
    },

    cancelModal() {
      this.modalOpen = false
    },

    openSetTemporaryPasswordModal(user) {
      this.setTemporaryPasswordUser = user
      this.setTemporaryPasswordModalOpen = true
    },

    closeSetTemporaryPasswordModal() {
      this.setTemporaryPasswordModalOpen = false
      this.setTemporaryPasswordUser = null
      this.temporaryPassword = ""
    },
    openDisableUserModal(user) {
      this.setTemporaryDisableUser = user
      this.disableUserModal = true
    },
    closeDisableUserModal() {
      this.setTemporaryDisableUser = null
      this.disableUserModal = false
    },
    async setTemporaryPassword() {
      if (!this.$refs.form.validate()) return
      this.setTemporaryPasswordLoading = true
      
      const res = await Controllers.UserController.SupportSetSupportAccountPassword(this.setTemporaryPasswordUser.id, this.temporaryPassword);

      if(res && !res.hasError) {
        this.$root.showSnackbar('Temporary password set successfully!', "success", 5000)
      }
      else this.$root.showSnackbar('Failed to set temporary password for user.', "error", 5000)

      this.setTemporaryPasswordLoading = false
      this.closeSetTemporaryPasswordModal();
    },

    async submitUser() {
      if(this.submitUserLoading) return
      this.submitUserLoading = true;
      // Validation
      if (this.modalUser.firstName == "" || this.modalUser.lastName == "" || this.modalUser.email == "") {
        this.$root.showSnackbar('First Name, Last Name and Email are required', "error", 5000) 
        this.submitUserLoading = false;
        return      
      }

      if(this.creatingUser) {
        const res = await Controllers.UserController.SupportCreateSupportAccount(this.modalUser)
        
        if(res && !res.hasError) {
          this.userList.push(res.data)
          this.$root.showSnackbar('User created successfully!', "success", 5000)
        }
        else this.$root.showSnackbar('Failed to create user.', "error", 5000)
      }
      else {
        const res = await Controllers.UserController.SupportUpdateSupportAccount(this.modalUser)

        if(res && !res.hasError) {
          const oldUser = this.userList.find(user => user.id == this.modalUser.id)
          Object.assign(oldUser, res.data)
          this.$root.showSnackbar('User edited successfully!', "success", 5000)
        }
        else this.$root.showSnackbar('Failed to edit user.', "error", 5000)
      }

      this.submitUserLoading = false
      this.modalOpen = false
    },

    async enableUser(user) {
      const res = await Controllers.UserController.SupportEnableSupportAccount(user.id)
      if(res && !res.hasError) {
        user.disabled = !user.disabled
        this.$root.showSnackbar('User enabled successfully!', "success", 5000)
      }
      else {
        this.$root.showSnackbar('Failed to enable User.', "error", 5000)
      }
    },

    async disableUser(user) {
      const res = await Controllers.UserController.SupportDisableSupportAccount(user.id)
      if(res && !res.hasError) {
        user.disabled = !user.disabled
        this.$root.showSnackbar('User disabled successfully!', "success", 5000)
        this.setTemporaryDisableUser = null
        this.disableUserModal = false
      }
      else {
        this.$root.showSnackbar('Failed to disable User.', "error", 5000)
        this.setTemporaryDisableUser = null
       this.disableUserModal = false
      }
    },

    async getUserList() {
      const email = Cognito.auth.signInUserSession.idToken.payload.email
      const res = await Controllers.UserController.SupportListAccounts()
      if(res && !res.hasError) this.userList = res.data.filter(d => d.email != email)
      else this.$root.showSnackbar('Failed to load users', "error", 5000)
    },
  },

  async created() {
    this.$root.updateTitle("User Maintenance")
    
    await Promise.all([
      this.getUserList(),
    ])

    this.loaded = true
  },
}
</script>